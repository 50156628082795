import { ButtonGroup, ButtonGroupProps, IconButton } from "@chakra-ui/react";
import {
	At,
	FacebookLogo,
	InstagramLogo,
	PinterestLogo,
	TiktokLogo,
} from "phosphor-react";
import LineLogo from "../branding/LineLogo";

export const SocialMediaLinks = (props: ButtonGroupProps) => (
	<ButtonGroup variant='ghost' color='gray.600' {...props}>
		<IconButton
			as='a'
			target={"_blank"}
			href='https://www.tiktok.com/@selflessstudio'
			aria-label='TikTok'
			icon={<TiktokLogo weight='fill' size={24} />}
		/>
		<IconButton
			as='a'
			target={"_blank"}
			href='https://www.facebook.com/selflessstudio'
			aria-label='Facebook'
			icon={<FacebookLogo weight='fill' size={24} />}
		/>
		<IconButton
			as='a'
			target={"_blank"}
			href='https://www.instagram.com/selflessstudio_/'
			aria-label='Instagram'
			icon={<InstagramLogo weight='fill' size={24} />}
		/>
		<IconButton
			as='a'
			target={"_blank"}
			href='https://www.pinterest.com/selflessstudio/'
			aria-label='Pinterest'
			icon={<PinterestLogo weight='fill' size={24} />}
		/>
		<IconButton
			as='a'
			target={"_blank"}
			href='https://lin.ee/COQbnCp'
			aria-label='Line Official'
			icon={<LineLogo w={6} h={6} />}
		/>
		<IconButton
			as='a'
			target={"_blank"}
			href='mailto:info@selfless.studio'
			aria-label='Email'
			icon={<At weight='fill' size={24} />}
		/>
	</ButtonGroup>
);
