import { getAnalytics, logEvent } from 'firebase/analytics';
import { getFirestore } from 'firebase/firestore';
import { GelatoCreateOrderSchema } from 'lib/gelato/types/gelatoCreateOrderSchema';
import { GelatoProductSchema } from 'lib/gelato/types/gelatoProductSchema';
import { GelatoQuoteSchema } from 'lib/gelato/types/gelatoQuoteSchema';
import { RecipientSchema } from 'lib/gelato/types/recipientSchema';
import { ReturnAddressSchema } from 'lib/gelato/types/returnAddressSchema';
import { debounce, omit } from 'lodash-es';
import { snapshot, subscribe } from 'valtio';
import { proxyWithComputed } from 'valtio/utils';

import crud from '@/firebase/crud';
import toast from '@/helpers/toast';
import { checkoutLog } from '@/logging/BrowserLogger';

import UIState from './UIState';

import type { CreateOrderResponse } from 'lib/gelato/types/createOrderResponse';
import type { QuoteOrderResponse } from 'lib/gelato/types/quoteOrderResponse';
import type { CurrencyIsoCode } from 'types/currencyIsoCode';
import type { PosterCartItem } from './PosterEditorState';

const INITIAL_CHECKOUT_STATE = {
  id: null as string | null, // Our internal order id
  customerId: null as string | null, // Stripe internal customer id
  language: 'en-US' as 'en-US' | 'th-TH',
  checkoutStep: 'information' as
    | 'information'
    | 'shipping'
    | 'payment'
    | 'confirmation',
  items: [] as Array<PosterCartItem>,
  paymentIntentId: null as string | null, // Stripe payment intent id
  currency: 'USD' as 'USD' | 'THB', // TODO: Use CurrencyIsoCode later
  recipientAddress: {} as RecipientSchema,
  shippingAddress: {} as RecipientSchema,
  hasShippingAddress: false,
  shippingMethod: 'normal' as 'normal' | 'priority' | 'express',
  paymentMethod: 'stripe',
  paymentDetails: {} as {
    paymentConfirmation: string | null;
  },
  paymentStatus: 'pending' as 'pending' | 'success' | 'failure' | 'cancelled',
  smsMarketing: true,
  emailMarketing: true,
  termsAccepted: true,
  // Gelato responses
  gelatoQuoteResponse: null as QuoteOrderResponse | null,
  gelatoCreateOrderResponse: null as CreateOrderResponse | null,
};

const PERSISTED_CHECKOUT_STATE_KEY = 'CHECKOUT_STATE';
const PERSISTED_STATE = JSON.parse(
  typeof localStorage !== 'undefined' && localStorage.getItem(
        PERSISTED_CHECKOUT_STATE_KEY,
      ) || '{}',
) as Partial<typeof INITIAL_CHECKOUT_STATE>;

const MIXED_STATE = {
  ...INITIAL_CHECKOUT_STATE,
  ...PERSISTED_STATE,
};

const CheckoutState = proxyWithComputed(
  MIXED_STATE,
  {
    shippingPrice: (state) => {
      return calculateShippingPrice(state);
    },
    formattedShippingPrice: (state) => {
      const locale = state.language;
      const currency = state.currency;
      const formatter = new Intl.NumberFormat(locale, {
        currency,
        style: 'currency',
        maximumFractionDigits: 2,
      });

      return formatter.format(calculateShippingPrice(state));
    },
    totalPrice: (state) => {
      const shippingPrice = calculateShippingPrice(state);

      return state.items.reduce((total, item) => {
        return total + item.quantity * item.price[state.currency];
      }, 0) + shippingPrice;
    },
    totalPriceForStripe: (state) => {
      // @ts-ignore
      const totalPrice = state.totalPrice;

      switch (state.currency) {
        case 'THB':
          return totalPrice * 100;
        case 'USD':
          return totalPrice * 100;
        default:
          return totalPrice * 100;
      }
    },
    formattedSubtotalPrice: (state) => {
      const locale = state.language;
      const currency = state.currency;
      const formatter = new Intl.NumberFormat(locale, {
        currency,
        style: 'currency',
        maximumFractionDigits: 2,
      });

      const totalPrice = state.items.reduce((total, item) => {
        return total + item.quantity * item.price[state.currency];
      }, 0);

      return formatter.format(totalPrice);
    },
    formattedTotalPrice: (state) => {
      const locale = state.language;
      const currency = state.currency;
      const formatter = new Intl.NumberFormat(locale, {
        currency,
        style: 'currency',
        maximumFractionDigits: 2,
      });

      const totalPrice = state.items.reduce((total, item) => {
        return total + item.quantity * item.price[state.currency];
      }, 0);

      const shippingPrice = calculateShippingPrice(state);

      return formatter.format(totalPrice + shippingPrice);
    },
    gelatoQuote: (state): GelatoQuoteSchema => {
      const products = state.items.map((item) => ({
        itemReferenceId: item.id, // TODO: test item id
        productUid: item.data.productUid,
        quantity: item.quantity,
        fileUrl: item.data.imageUrl || '',
        pageCount: 1,
      }));

      const recipient: RecipientSchema = {
        country: state.recipientAddress.country || '',
        firstName: state.recipientAddress.firstName || '',
        lastName: state.recipientAddress.lastName || '',
        addressLine1: state.recipientAddress.addressLine1 || '',
        city: state.recipientAddress.city || '',
        postCode: state.recipientAddress.postCode || '',
        email: state.recipientAddress.email || '',
      };

      return {
        orderReferenceId: state.id || '',
        customerReferenceId: state.customerId || '',
        recipient,
        products,
        currency: state.currency,
      };
    },
    gelatoCreateOrder: (state): GelatoCreateOrderSchema => {
      const orderType = 'draft'; // TODO: Test this with draft first
      const orderReferenceId = state.id || '';
      const customerReferenceId = state.customerId || '';
      const currency = state.currency;

      const items: GelatoProductSchema[] = state.items.map((item) => ({
        itemReferenceId: item.id,
        productUid: item.data.productUid || '',
        quantity: item.quantity,
        fileUrl: item.data.imageUrl || '',
        pageCount: 1,
      }));

      const shipmentMethodUid = state.shippingMethod === 'priority'
        ? 'normal'
        : state.shippingMethod;

      const shippingAddress: RecipientSchema = {
        country: state.recipientAddress.country || '',
        firstName: state.recipientAddress.firstName || '',
        lastName: state.recipientAddress.lastName || '',
        addressLine1: state.recipientAddress.addressLine1 || '',
        city: state.recipientAddress.city || '',
        postCode: state.recipientAddress.postCode || '',
        email: state.recipientAddress.email || '',
      };

      const returnAddress: ReturnAddressSchema = {
        companyName: 'Selfless Studio',
      };

      return {
        orderType,
        orderReferenceId,
        customerReferenceId,
        currency,
        items,
        shipmentMethodUid,
        shippingAddress,
        returnAddress,
      };
    },
  },
);

export type CheckoutStateInterface = typeof CheckoutState;

export function formatPrice(
  value: number,
  opts: { locale?: string; currency?: string; } = {},
) {
  const { locale = 'th-TH', currency = 'THB' } = opts;
  const formatter = new Intl.NumberFormat(locale, {
    currency,
    style: 'currency',
    maximumFractionDigits: 0,
  });
  return formatter.format(value);
}

// Temporary mapping for shipping prices in USD and THB
// TODO: Move this to some kind of config file or database
export const SHIPPING_PRICES = {
  normal: {
    USD: 0,
    THB: 0,
  },
  priority: {
    USD: 5,
    THB: 100,
  },
  express: {
    USD: 10,
    THB: 300,
  },
};

/** Get the shipping price for a method in a nice format */
export const getFormattedShippingPrice = (
  { currency, language, shippingMethod = 'normal' }: {
    shippingMethod: 'normal' | 'priority' | 'express';
    currency?: CurrencyIsoCode;
    language?: string;
  },
) => {
  const state = snapshot(CheckoutState);

  return formatPrice(
    SHIPPING_PRICES[shippingMethod][
      (currency as 'USD' | 'THB') || state.currency
    ],
    {
      currency: currency || state.currency,
      locale: language || state.language,
    },
  );
};

export function calculateShippingPrice(state: unknown): number {
  const s = state as CheckoutStateInterface;

  return SHIPPING_PRICES[s.shippingMethod][s.currency as 'USD' | 'THB'];
}

/** Internal Methods */
const addCheckout = debounce(async (state) => {
  const db = getFirestore();
  const doc = await crud.add(db, 'orders', { ...state });
  const newId = doc.id;
  CheckoutState.id = newId;
  /** Show creates in development */
  if (process.env.NODE_ENV === 'development') {
    // @ts-ignore
    toast({
      title: `Created document: orders/${newId}`,
    });
    checkoutLog.info({ ...state }, `Created document: orders/${newId}`);
  }
}, 1000);

const updateCheckout = debounce(
  async (state) => {
    const db = getFirestore();
    const doc = await crud.update(db, 'orders', state.id, { ...state });
    /** Show updates in development */
    if (process.env.NODE_ENV === 'development') {
      // @ts-ignore
      toast({
        title: `Updated document: orders/${doc.id}`,
      });
    }
    checkoutLog.info({ ...state }, `Updated document: orders/${doc.id}`);
  },
  1000,
  { leading: false, trailing: true },
);

/** Update state in local storage */
const updatePersistedCheckoutState = (): void => {
  const state = snapshot(CheckoutState);
  const persistedState: Partial<typeof INITIAL_CHECKOUT_STATE> = {
    currency: state.currency,
  };
  localStorage.setItem(
    PERSISTED_CHECKOUT_STATE_KEY,
    JSON.stringify(persistedState),
  );
};

/** Public methods */
export const createQuote = debounce(
  async () => {
    const state = snapshot(CheckoutState);

    const response = await fetch('/api/gelato/create-quote', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ quote: state.gelatoQuote }),
    });

    const data = await response.json();

    CheckoutState.gelatoQuoteResponse = data;
  },
  1000,
  { leading: true, trailing: false },
);

// TODO: Add a reset method like here: https://github.com/pmndrs/valtio/wiki/How-to-reset-state

/** Subscription */
subscribe(CheckoutState, async () => {
  const analytics = getAnalytics();
  const uiState = snapshot(UIState);
  const state = snapshot(CheckoutState);

  updatePersistedCheckoutState();

  if (!uiState.isCartOpen) return;

  try {
    if (state.id) {
      /** Clean the state, because we don't want these values to be overwritten by accident */
      const cleanState = omit(state, [
        'gelatoQuoteResponse',
        'gelatoCreateOrderResponse',
      ]);
      await updateCheckout(
        cleanState,
      );
    } else {
      await addCheckout(
        state,
      );
    }
    // Create a quote when in shipping step
    if (
      state.id
      && state.checkoutStep === 'shipping'
      && !state.gelatoQuoteResponse
    ) {
      await createQuote();
      // GA Track a custom event when entering shipping state
      logEvent(analytics, 'add_shipping_info');
    }
    // Track adding payment info
    if (
      state.id
      && state.checkoutStep === 'payment'
      && state.gelatoCreateOrderResponse
      && typeof window.fbq === 'function'
    ) {
      window.fbq('track', 'AddPaymentInfo');
      // GA Track a custom event when entering payment info
      logEvent(analytics, 'add_payment_info');
    }
  } catch (error) {
    if (error instanceof Error && process.env.NODE_ENV === 'development') {
      // @ts-ignore
      toast({
        title: `Error occured: ${error.message}`,
        status: 'error',
      });
      console.error(error);
    }
  }
});

export default CheckoutState;
