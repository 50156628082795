import { getAnalytics, logEvent } from 'firebase/analytics';
import { getApp } from 'firebase/app';
import useTranslation from 'next-translate/useTranslation';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import { CaretLeft, Leaf, MapPinLine, Truck } from 'phosphor-react';
import * as React from 'react';
import { useSnapshot } from 'valtio';

import {
  Box,
  Button,
  Divider,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerOverlay,
  Flex,
  Grid,
  GridItem,
  Heading,
  HStack,
  Image,
  Select,
  SelectProps,
  Skeleton,
  Stack,
  StackProps,
  Text,
  TextProps,
  useColorModeValue,
} from '@chakra-ui/react';

import CheckoutState, { formatPrice } from '../state/CheckoutState';
import { PosterCartItem } from '../state/PosterEditorState';
import UIState from '../state/UIState';
import { CheckoutBreadcrumbs } from './checkout/CheckoutBreadcrumbs';

/** Dynamically import AddressFields */
const AddressFields = dynamic(
  () => import('./checkout/AddressFields'),
  {
    ssr: false,
  },
);
/** Dynamically import ShippingFields */
const ShippingFields = dynamic(
  () => import('./checkout/ShippingFields'),
  {
    ssr: false,
  },
);
/** Dynamically import PaymentFields */
const PaymentFields = dynamic(
  () => import('./checkout/PaymentFields'),
  {
    ssr: false,
  },
);

export interface CartItemData {
  id: string;
  price: number;
  quantity: number;
  currency: string;
  name: string;
  description: string;
  imageUrl: string;
}

interface PriceTagProps {
  currency: string;
  price: number;
  salePrice?: number;
  rootProps?: StackProps;
  priceProps?: TextProps;
  salePriceProps?: TextProps;
}

export type FormatPriceOptions = { locale?: string; currency?: string; };

export const PriceTag = (props: PriceTagProps) => {
  const { price, currency, salePrice, rootProps, priceProps, salePriceProps } =
    props;
  return (
    <HStack spacing='1' { ...rootProps }>
      <Price isOnSale={ !!salePrice } textProps={ priceProps }>
        { formatPrice(price, { currency }) }
      </Price>
      { salePrice && (
        <SalePrice { ...salePriceProps }>
          { formatPrice(salePrice, { currency }) }
        </SalePrice>
      ) }
    </HStack>
  );
};

interface PriceProps {
  children?: React.ReactNode;
  isOnSale?: boolean;
  textProps?: TextProps;
}

const Price = (props: PriceProps) => {
  const { isOnSale, children, textProps } = props;
  const defaultColor = useColorModeValue('gray.700', 'gray.400');
  const onSaleColor = useColorModeValue('gray.400', 'gray.700');
  const color = isOnSale ? onSaleColor : defaultColor;
  return (
    <Text
      as='span'
      fontWeight='medium'
      color={ color }
      textDecoration={ isOnSale ? 'line-through' : 'none' }
      { ...textProps }
    >
      { children }
    </Text>
  );
};

const SalePrice = (props: TextProps) => (
  <Text
    as='span'
    fontWeight='semibold'
    color={ useColorModeValue('gray.800', 'gray.100') }
    { ...props }
  />
);

export type CartProductMetaProps = {
  isGiftWrapping?: boolean;
  name: string;
  description: string;
  image?: string;
  smallImage?: string;
  blurPreview?: string;
  quantity: number;
  index: number;
};

export const CartProductMeta = React.memo((props: CartProductMetaProps) => {
  const {
    name,
    description,
    quantity,
    index,
    smallImage,
    blurPreview,
  } = props;
  const checkoutState = useSnapshot(CheckoutState);

  return (
    <Stack direction='row' spacing='5' width='full' align='center'>
      { /* TODO: Add back the preview image */ }
      {
        /* <Box
        width='120px'
        boxShadow={ 'xl' }
        border='1px'
        borderColor='gray.100'
        overflow='hidden'
        sx={ {
          '& .blur': {
            position: 'absolute !important',
            maxW: '100% !important',
            width: '100%',
          },
        } }
      >
        { !blurPreview && (
          <Skeleton
            startColor='selfless.100'
            endColor='selfless.500'
            height={ 120 }
          />
        ) }
        { blurPreview && (
          <Image
            position={ 'relative' }
            width={ '100%' }
            zIndex={ 2 }
            fit='cover'
            src={ smallImage || blurPreview }
            alt={ name }
            draggable='false'
            loading='lazy'
            filter={ !smallImage ? 'blur(10px)' : undefined }
          />
        ) }
      </Box> */
      }
      <Box w='100%'>
        <Stack spacing='0.5'>
          <Text fontWeight='medium'>{ name }</Text>
          <Text
            color={ useColorModeValue('gray.600', 'gray.400') }
            fontSize='sm'
          >
            { description }
          </Text>
        </Stack>
        <Stack mt='2' spacing={ 2 } direction={ 'row' } align={ 'center' }>
          <QuantitySelect
            value={ quantity }
            onChange={ (e) => {
              CheckoutState.items[index].quantity = parseInt(e.target.value);
            } }
          />
          <Button
            tabIndex={ -1 }
            variant={ 'ghost' }
            colorScheme={ 'gray' }
            size='sm'
            opacity={ .5 }
            _hover={ {
              opacity: 1,
            } }
            onClick={ () => {
              // Remove item at the index
              CheckoutState.items = checkoutState.items.filter(
                (item, i) => i !== index,
              );
            } }
          >
            Remove
          </Button>
        </Stack>
        {
          /* { isGiftWrapping && (
          <HStack
            spacing='1'
            mt='3'
          >
            <Icon
              as={ Gift }
              boxSize='4'
              color={ useColorModeValue('gray.600', 'gray.400') }
            />
            <Link
              fontSize='sm'
              textDecoration='underline'
              color={ useColorModeValue('gray.600', 'gray.400') }
            >
              Add gift wrapping
            </Link>
          </HStack>
        ) } */
        }
      </Box>
    </Stack>
  );
});

CartProductMeta.displayName = 'CartProductMeta';

type OrderSummaryItemProps = {
  label: string;
  value?: string;
  children?: React.ReactNode;
};

const OrderSummaryItem = (props: OrderSummaryItemProps) => {
  const { label, value, children } = props;
  return (
    <Flex justify='space-between' fontSize='sm'>
      <Text
        fontWeight='medium'
        color={ useColorModeValue('gray.600', 'gray.400') }
      >
        { label }
      </Text>
      { value ? <Text fontWeight='medium'>{ value }</Text> : children }
    </Flex>
  );
};

export const OrderSummary = () => {
  const { t } = useTranslation('common');
  const {
    formattedTotalPrice,
    formattedSubtotalPrice,
    formattedShippingPrice,
  } = useSnapshot(CheckoutState);

  return (
    <Stack spacing='8' width='full'>
      <Stack spacing={ { base: '2', lg: '6' } }>
        <OrderSummaryItem
          label={ t('Subtotal') }
          value={ formattedSubtotalPrice }
        />
        <OrderSummaryItem
          label={ t('Shipping') }
          value={ formattedShippingPrice }
        />
        {
          /* <OrderSummaryItem label="Shipping + Tax">
					<Link href="#" textDecor="underline">
						Calculate shipping
					</Link>
				</OrderSummaryItem> */
        }
        {
          /* <OrderSummaryItem label="Coupon Code">
					<Link href="#" textDecor="underline">
						Add coupon code
					</Link>
				</OrderSummaryItem> */
        }
        <Flex justify='space-between'>
          <Text fontSize='lg' fontWeight='semibold'>
            { t('Total') }
          </Text>
          <Text fontSize='xl' fontWeight='extrabold'>
            { formattedTotalPrice }
          </Text>
        </Flex>
      </Stack>
      {
        /* <Button variant='black' size="xl" rightIcon={<ArrowRight size={24} />}>
				Place order
			</Button> */
      }
    </Stack>
  );
};

const QuantitySelect = (props: SelectProps) => {
  return (
    <Select
      maxW='64px'
      aria-label='Select quantity'
      focusBorderColor={ useColorModeValue('blue.500', 'blue.200') }
      tabIndex={ -1 }
      size='sm'
      { ...props }
    >
      <option value='0'>0</option>
      <option value='1'>1</option>
      <option value='2'>2</option>
      <option value='3'>3</option>
      <option value='4'>4</option>
      <option value='5'>5</option>
      <option value='6'>6</option>
      <option value='7'>7</option>
      <option value='8'>8</option>
      <option value='9'>9</option>
      <option value='10'>10</option>
    </Select>
  );
};

type CartItemProps = PosterCartItem & {
  index: number;
  onChangeQuantity?: (quantity: number) => void;
  onClickGiftWrapping?: () => void;
  onClickDelete?: () => void;
};

export const CartItem = React.memo(({
  index,
  data,
  price,
  quantity,
}: CartItemProps) => {
  const checkoutState = useSnapshot(CheckoutState);

  return (
    <Flex
      direction={ { base: 'row' } }
      justify='space-between'
      align='baseline'
    >
      <CartProductMeta
        name={ 'Custom Poster Print' } // TODO: Change product name
        description={ `${
          data.posterSize.split('x').join(' x ')
        } - Color: ${data.design.colorScheme.toLocaleUpperCase()}` }
        image={ data.imageUrl }
        smallImage={ data.smallImageUrl }
        blurPreview={ data.imageBlurPreview }
        isGiftWrapping={ false }
        quantity={ quantity }
        index={ index }
      />

      { /* Desktop */ }
      <Flex
        justify='space-between'
        align={ 'flex-end' }
        flexDirection={ 'column' }
        h='100%'
      >
        {
          /* <CloseButton aria-label={`Delete Love Poster from cart`} onClick={() => {
					CheckoutState.items.splice(index, 1);
				}} /> */
        }
        <PriceTag
          price={ price[checkoutState.currency as keyof typeof price] }
          currency={ checkoutState.currency }
        />
      </Flex>
    </Flex>
  );
});

CartItem.displayName = 'CartItem';

export const Checkout = React.memo(() => {
  const { t } = useTranslation('common');
  const router = useRouter();
  const checkoutState = useSnapshot(CheckoutState);
  const checkoutStep = checkoutState.checkoutStep;

  React.useEffect(() => {
    router.beforePopState(({ as }) => {
      if (as !== router.asPath) {
        // Will run when leaving the current page; on back/forward actions
        // Add your logic here, like toggling the modal state
        UIState.isCartOpen = false;
        CheckoutState.checkoutStep = 'information';
      }
      return true;
    });

    return () => {
      router.beforePopState(() => true);
    };
  }, [router]);

  return (
    <Grid templateColumns='repeat(6, 1fr)' w='100%' mx='auto' h='100%'>
      <GridItem
        bg='selfless.200'
        p={ { base: 4, md: 8, lg: 12 } }
        rowSpan={ 1 }
        colSpan={ { base: 6, md: 3 } }
      >
        <Stack
          position={ ['relative', 'sticky'] }
          top={ [4, 8] }
          direction={ { base: 'column' } }
          align={ { lg: 'flex-start' } }
          spacing={ { base: 4, lg: 8 } }
          w='full'
          maxW='2xl'
          mx='auto'
        >
          <Stack spacing={ { base: 4, md: 8, lg: 12 } } flex='2' w='full'>
            <Button
              leftIcon={ <CaretLeft size={ 24 } /> }
              variant={ 'link' }
              textAlign={ 'left' }
              alignItems={ 'flex-start' }
              w='min'
              onClick={ () => {
                UIState.isCartOpen = false;
              } }
              tabIndex={ -1 }
              colorScheme={ 'gray' }
            >
              Back
            </Button>
            <Heading fontSize='lg' fontWeight='extrabold'>
              { t('Order Summary') } ({ checkoutState.items.length }{' '}
              { t('items') })
            </Heading>

            <Stack spacing='6' w='100%'>
              { checkoutState.items.map((
                item: PosterCartItem,
                index: number,
              ) => <CartItem key={ item.id } index={ index } { ...item } />) }
            </Stack>
          </Stack>

          <Divider borderColor='selfless.600' />

          <OrderSummary />

          <Divider borderColor='selfless.600' />

          <Stack
            color='gray.500'
            spacing={ { base: 4, lg: 6 } }
            direction={ 'row' }
            align={ 'center' }
            justify={ 'center' }
            w='full'
          >
            <Stack direction={ 'row' } align={ 'center' } color='selfless.800'>
              <Truck size={ 32 } weight='duotone' />
              <Text fontSize={ { base: 'sm', md: 'md' } }>
                { t('Free shipping') }
              </Text>
            </Stack>

            <Stack direction={ 'row' } align={ 'center' } color='selfless.800'>
              <MapPinLine size={ 32 } weight='duotone' />
              <Text fontSize={ { base: 'sm', md: 'md' } }>
                { t('Produced locally') }
              </Text>
            </Stack>

            <Stack direction={ 'row' } align={ 'center' } color='selfless.800'>
              <Leaf size={ 32 } weight='duotone' />
              <Text fontSize={ { base: 'sm', md: 'md' } }>
                { t('Greener Printing') }
              </Text>
            </Stack>
          </Stack>
        </Stack>
      </GridItem>

      <GridItem
        p={ { base: 4, md: 8, lg: 12 } }
        pt={ { base: 8 } }
        rowSpan={ 1 }
        colSpan={ [6, 3] }
      >
        <Stack
          direction={ { base: 'column' } }
          align={ { lg: 'flex-start' } }
          spacing={ { base: '8' } }
          maxW='2xl'
          mx='auto'
        >
          <Stack spacing={ { base: '4' } } flex='2' w='100%'>
            <CheckoutBreadcrumbs />

            { checkoutStep === 'information'
              && <AddressFields addressType='recipientAddress' /> }

            { checkoutStep === 'shipping'
              && <ShippingFields /> }

            { checkoutStep === 'payment'
              && <PaymentFields /> }
          </Stack>
        </Stack>
      </GridItem>
    </Grid>
  );
});

Checkout.displayName = 'Checkout';

export const CheckoutDrawer = () => {
  const router = useRouter();
  const { isCartOpen } = useSnapshot(UIState);

  React.useEffect(() => {
    const app = getApp();
    const analytics = getAnalytics(app);

    if (isCartOpen) {
      router.push({
        query: router.query,
        pathname: router.pathname,
        hash: '#checkout',
      });

      logEvent(analytics, 'begin_checkout', {
        // TODO: Add analytics data
        // currency: cartItem.currency,
        // value: cartItem.price,
        // items: [
        // 	{
        // 		item_id: cartItem.item_id,
        // 		item_name: cartItem.type,
        // 		item_category: 'poster',
        // 	}
        // ]
      });
    }
    if (isCartOpen === false) {
      router.push({
        query: router.query,
        pathname: router.pathname,
        hash: '',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCartOpen]);

  return (
    <>
      <Drawer
        placement={ 'bottom' }
        size={ 'full' }
        onClose={ () => {
          UIState.isCartOpen = false;
        } }
        isOpen={ isCartOpen }
      >
        <DrawerOverlay />
        <DrawerContent>
          {
            /* <DrawerHeader textAlign={'center'}>
						Cart
					</DrawerHeader> */
          }
          <DrawerBody p='0'>
            <Checkout />
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
};
