import useTranslation from "next-translate/useTranslation";
import Link from "next/link";
import FullLogo from "../branding/FullLogo";
import { SocialMediaLinks } from "./SocialMediaLinks";

const ExpandedFooter = () => {
	const { t, lang } = useTranslation("common");

	return (
		<footer className='bg-white border-t border-gray-100'>
			{/* <div className='py-6 bg-caramel-500'>
				<div className='px-4 mx-auto max-w-screen-2xl md:px-8'>
					<div className='flex flex-col items-center justify-between gap-2 md:flex-row'>
						<div className='mb-3 text-center md:mb-0 md:text-left'>
							<span className='font-bold tracking-widest text-gray-100 uppercase'>
								Newsletter
							</span>
							<p className='text-caramel-200'>Subscribe to our newsletter</p>
						</div>

						<form className='flex w-full gap-2 md:max-w-md'>
							<input
								placeholder='Email'
								className='flex-1 w-full px-3 py-2 text-white transition duration-100 border border-white rounded outline-none placeholder-caramel-100 bg-caramel-400 ring-caramel-300 focus:ring'
							/>

							<button className='inline-block px-8 py-2 text-sm font-semibold text-center transition duration-100 bg-white rounded outline-none text-caramel-500 ring-caramel-300 hover:bg-gray-100 focus-visible:ring active:text-caramel-600 md:text-base'>
								Send
							</button>
						</form>
					</div>
				</div>
			</div> */}

			<div className='pt-12 lg:pt-16'>
				<div className='px-4 mx-auto max-w-screen-2xl md:px-8'>
					<div className='grid grid-cols-2 gap-12 mb-16 md:grid-cols-4 lg:grid-cols-6 lg:gap-8'>
						<div className='col-span-full lg:col-span-2'>
							{/* <!-- logo - start --> */}
							<div className='mb-4 lg:-mt-2'>
								<Link
									href='/'
									className='inline-flex flex-col'
									aria-label='Selfless Studio Logo'>
									<FullLogo height={12} width={"90%"} />
								</Link>
							</div>
							{/* <!-- logo - end --> */}

							<p className='mb-6 text-gray-500 sm:pr-8'>{t("footer_description")}</p>

							<p className='mb-6 text-xs text-gray-500 sm:pr-8'>
								ARTISAN RATCHADA อาร์ติซาน รัชดา, 99 THIAM RUAM MIT RD, HUAI KHWANG,
								Bangkok 10310, Thailand
							</p>

							<p className='mb-4 text-xs font-bold tracking-widest text-gray-800 uppercase'>
								Social Media Links
							</p>
							<SocialMediaLinks />
						</div>

						{/* <!-- nav - start --> */}
						<div>
							<div className='mb-4 text-xs font-bold tracking-widest text-gray-800 uppercase'>
								{t("Products")}
							</div>

							<nav className='flex flex-col gap-4'>
								<div>
									<Link
										href={{
											pathname: "/product/",
											href: "/product/",
										}}
										className='text-gray-500 transition duration-100 hover:text-caramel-500 active:text-caramel-600'>
										{t("Products Overview")}
									</Link>
								</div>
							</nav>
						</div>
						{/* <!-- nav - end --> */}

						{/* <!-- nav - start --> */}
						<div>
							<div className='mb-4 text-xs font-bold tracking-widest text-gray-800 uppercase'>
								{t("Company")}
							</div>

							<nav className='flex flex-col gap-4'>
								{/* <div>
									<a
										href='#'
										className='text-gray-500 transition duration-100 hover:text-caramel-500 active:text-caramel-600'>
										{t("About us")}
									</a>
								</div> */}

								<div>
									<Link
										href={{
											pathname: "/blog/",
											href: "/blog/",
										}}
										className='text-gray-500 transition duration-100 hover:text-caramel-500 active:text-caramel-600'>
										{t("Blog")}
									</Link>
								</div>
							</nav>
						</div>
						{/* <!-- nav - end --> */}

						{/* <!-- nav - start --> */}
						{/* <div>
							<div className='mb-4 text-xs font-bold tracking-widest text-gray-800 uppercase'>
								{t("Customer Support")}
							</div>

							<nav className='flex flex-col gap-4'>
								<div>
									<a
										href='#'
										className='text-gray-500 transition duration-100 hover:text-caramel-500 active:text-caramel-600'>
										{t("Contact")}
									</a>
								</div>

								<div>
									<a
										href='#'
										className='text-gray-500 transition duration-100 hover:text-caramel-500 active:text-caramel-600'>
										FAQ
									</a>
								</div>
							</nav>
						</div> */}
						{/* <!-- nav - end --> */}

						{/* <!-- nav - start --> */}
						<div>
							<div className='mb-4 text-xs font-bold tracking-widest text-gray-800 uppercase'>
								Legal
							</div>

							<nav className='flex flex-col gap-4'>
								<div>
									<Link
										href={{
											pathname: "/[slug]",
											query: { slug: "terms-and-conditions" },
										}}
										className='text-gray-500 transition duration-100 hover:text-caramel-500 active:text-caramel-600'>
										{t("Terms and Conditions")}
									</Link>
								</div>
							</nav>
						</div>
						{/* <!-- nav - end --> */}
					</div>

					<div className='py-8 text-sm text-center text-gray-400 border-t'>
						© {new Date().getFullYear()} - Selfless Studio. All rights reserved.{" "}
						<a
							href='https://systemsloop.com?ref=selfless.studio'
							rel='follow noopener'
							target={"_blank"}>
							This site is developed and managed by Systems Loop.
						</a>
					</div>
				</div>
			</div>
		</footer>
	);
};

export default ExpandedFooter;
