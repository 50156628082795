import { getApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  DocumentData,
  DocumentReference,
  DocumentSnapshot,
  Firestore,
  getDoc,
  getDocs,
  Timestamp,
  updateDoc,
} from 'firebase/firestore';

/** Add _createdBy or _updatedBy metadata   */
const addMetadata = <T>(
  obj: T,
  type: Array<'_createdBy' | '_updatedBy'>,
) => {
  const app = getApp();
  const auth = getAuth(app);

  const user = auth.currentUser
    ? {
      uid: auth.currentUser?.uid,
      displayName: auth.currentUser?.displayName,
      email: auth.currentUser?.email,
      photoURL: auth.currentUser?.photoURL,
      isAnonymous: auth.currentUser?.isAnonymous,
    }
    : {
      isAnonymous: true,
    };

  const objWithMetadata = { ...obj };

  type.forEach(key => {
    // @ts-ignore
    objWithMetadata[key] = {
      timestamp: Timestamp.now(),
      ...user,
    };
  });

  return objWithMetadata;
};

/** Get all documents of a collection */
const get = async (
  db: Firestore,
  collectionPath: string,
): Promise<DocumentData[]> => {
  const collectionRef = await collection(db, collectionPath);
  const snapshot = await getDocs(collectionRef);
  const items = snapshot.docs.map(doc => doc.data());
  return items;
};

/** Get one document by id */
const getOne = async (
  db: Firestore,
  collectionPath: string,
  id: string,
): Promise<DocumentSnapshot<DocumentData>> => {
  const collectionRef = await collection(db, collectionPath);
  const docRef = await doc(collectionRef, id);
  const data = await getDoc(docRef);
  return data;
};

/** Add a document in firestore */
const add = async (db: Firestore, collectionPath: string, obj: any) => {
  const collectionRef = collection(db, collectionPath);
  const docRef = await addDoc(
    collectionRef,
    addMetadata(obj, ['_createdBy', '_updatedBy']),
  );
  return docRef;
};

/**
 * Update a document by it's id
 */
const update = async (
  db: Firestore,
  collectionPath: string,
  id: string,
  obj: any,
): Promise<DocumentReference<DocumentData>> => {
  const docRef = doc(db, collectionPath, id);
  await updateDoc(docRef, addMetadata(obj, ['_updatedBy']));
  return docRef;
};

/** Delete a document by it's id */
const del = async (
  db: Firestore,
  collectionPath: string,
  id: string,
): Promise<DocumentReference<DocumentData>> => {
  const docRef = doc(db, collectionPath, id);
  await deleteDoc(docRef);
  return docRef;
};

const crud = {
  add,
  get,
  getOne,
  update,
  del,
};

export default crud;
