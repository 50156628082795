import setLanguage from "next-translate/setLanguage";
import useTranslation from "next-translate/useTranslation";
import { CaretDoubleDown, Star, Truck } from "phosphor-react";
import React from "react";
import CheckoutState from "state/CheckoutState";
import { useSnapshot } from "valtio";

import {
	Box,
	Button,
	Flex,
	Grid,
	GridItem,
	HStack,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	Select,
	Stack,
	Text,
	useDisclosure,
} from "@chakra-ui/react";

const LocaleSettingsModal = () => {
	const { t, lang } = useTranslation("common");
	const { isOpen, onOpen, onClose } = useDisclosure();

	const checkoutState = useSnapshot(CheckoutState, { sync: true });

	React.useEffect(() => {
		if (lang === "en") {
			CheckoutState.language = "en-US";
		}
		if (lang === "th") {
			CheckoutState.language = "th-TH";
		}
	}, [lang, checkoutState.language]);

	return (
		<>
			<Button
				color='selfless.800'
				rightIcon={<CaretDoubleDown weight='bold' />}
				variant={"link"}
				w='min'
				p='2'
				size={"xs"}
				fontSize={"xs"}
				fontWeight={"bold"}
				onClick={onOpen}>
				{lang === "en" ? "English" : "Thai - ไทย"} ({checkoutState.currency})
			</Button>

			<Modal isOpen={isOpen} onClose={onClose}>
				<ModalOverlay />
				<ModalContent>
					<ModalHeader>{t("Select your language and currency")}</ModalHeader>
					<ModalCloseButton />
					<ModalBody>
						<Stack spacing={4}>
							<Select
								placeholder={t("Select your language")}
								value={checkoutState.language}
								onChange={(e) => {
									const value = e.target.value as "en-US" | "th-TH";
									CheckoutState.language = value;
									setLanguage(value.substring(0, 2));
								}}>
								<option value='en-US'>English</option>
								<option value='th-TH'>ไทย - Thai</option>
							</Select>
							<Select
								placeholder={t("Select your currency")}
								value={checkoutState.currency}
								onChange={(e) => {
									CheckoutState.currency = e.target.value as "USD" | "THB";
								}}>
								<option value='USD'>{t("US Dollar - $")}</option>
								<option value='THB'>{t("Thai Baht - ฿")}</option>
							</Select>
						</Stack>
					</ModalBody>

					<ModalFooter>
						<Button colorScheme='blue' onClick={onClose}>
							{t("Close")}
						</Button>
					</ModalFooter>
				</ModalContent>
			</Modal>
		</>
	);
};

const MetaNavigation = React.memo(() => {
	const { t } = useTranslation("common");

	return (
		<Box as='nav' mx='auto' position={"relative"} w={"100%"} bg='selfless.100'>
			<Flex
				maxW='7xl'
				color={"selfless.900"}
				minH={"32px"}
				h='32px'
				py={{ base: 0 }}
				px={{ base: 4 }}
				align={"center"}
				justify='center'
				mx={"auto"}>
				<Grid
					templateColumns={{ base: "repeat(3,1fr)", md: "repeat(3,1fr)" }}
					w='full'
					alignItems='center'>
					<GridItem
						colSpan={{ base: 1 }}
						display={{ base: "none", md: "block" }}
						w='full'
						fontSize={"xs"}
						fontWeight={"bold"}>
						<HStack align='center' spacing={{ base: 1 }} color='goldenrod'>
							<Star weight='fill' />
							<Star weight='fill' />
							<Star weight='fill' />
							<Star weight='fill' />
							<Star weight='duotone' />
							<Text pl={1} color={"selfless.900"}>
								4.9 / 5.0
							</Text>
						</HStack>
					</GridItem>
					<GridItem
						colSpan={{ base: 2, md: 1 }}
						w='full'
						fontSize={"xs"}
						fontWeight={"bold"}>
						<Stack
							direction={"row"}
							align={"center"}
							justify={"center"}
							spacing={{ base: 2 }}>
							<Truck size={20} weight='duotone' />
							<Text>{t("Free shipping")}</Text>
						</Stack>
					</GridItem>
					<GridItem colSpan={{ base: 1 }} w='full'>
						<Flex justify={"flex-end"}>
							<LocaleSettingsModal />
						</Flex>
					</GridItem>
				</Grid>
			</Flex>
		</Box>
	);
});

MetaNavigation.displayName = "MetaNavigation";

export default MetaNavigation;
