import { proxyWithComputed } from 'valtio/utils';

const UIState = proxyWithComputed(
  {
    isCartOpen: false,
    language: 'en-US',
  },
  {},
);

export default UIState;
