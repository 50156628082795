import NextLink from "next/link";
import { ShoppingBagOpen } from "phosphor-react";
import React from "react";
import { useSnapshot } from "valtio";

import { CheckoutDrawer } from "@/components/Checkout";
import CheckoutState from "@/state/CheckoutState";
import UIState from "@/state/UIState";
import { Badge, Box, Flex, Grid, GridItem, IconButton } from "@chakra-ui/react";

import FullLogo from "../branding/FullLogo";
import MetaNavigation from "./MetaNavigation";

const MainNavigation = () => {
	const checkoutState = useSnapshot(CheckoutState);
	const uiState = useSnapshot(UIState);

	return (
		<Flex
			maxW='7xl'
			color={"selfless.900"}
			minH={12}
			py={{ base: 4, md: 4 }}
			px={{ base: 4 }}
			align={"center"}
			justify='center'
			mx={"auto"}>
			<Grid
				templateColumns={{ base: "repeat(3,1fr)", md: "repeat(3,1fr)" }}
				w='full'
				alignItems='center'>
				<GridItem
					colSpan={{ base: 1 }}
					display={{ base: "none", md: "block" }}
					w='full'></GridItem>
				<GridItem colSpan={{ base: 2, md: 1 }} w='full'>
					<Flex
						w='full'
						flex={{ base: 1 }}
						justify={{ base: "center", md: "center" }}>
						<NextLink
							href={{
								pathname: "/",
							}}>
							<FullLogo w='auto' h='24px' />
						</NextLink>
					</Flex>
				</GridItem>
				<GridItem colSpan={{ base: 1 }} w='full'>
					<Flex w='full' justifyContent={"flex-end"}>
						<Box position={"relative"}>
							<IconButton
								variant={"ghost"}
								aria-label='Cart'
								// color='blackAlpha.900'
								colorScheme={"blackAlpha"}
								size='lg'
								icon={<ShoppingBagOpen size={24} />}
								onClick={() => {
									UIState.isCartOpen = true;
								}}
							/>
							{checkoutState.items.length > 0 && (
								<Badge
									display={"inline-block"}
									pointerEvents='none'
									position={"absolute"}
									top='1'
									right='1'
									minW={"4"}
									variant={"solid"}
									borderRadius='xl'
									textAlign={"center"}
									fontWeight={900}
									size={"lg"}>
									{checkoutState.items.length}
								</Badge>
							)}
						</Box>
					</Flex>
				</GridItem>
			</Grid>
		</Flex>
	);
};

const NavigationMinimal = React.memo(() => {
	return (
		<Box as='nav' mx='auto' position={"relative"} w={"100%"}>
			<MetaNavigation />
			<MainNavigation />
			<CheckoutDrawer />
		</Box>
	);
});

NavigationMinimal.displayName = "NavigationMinimal";

export default NavigationMinimal;
