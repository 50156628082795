import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
} from '@chakra-ui/react';
import useTranslation from 'next-translate/useTranslation';
import { CaretRight } from 'phosphor-react';
import * as React from 'react';
import { useSnapshot } from 'valtio';
import CheckoutState from '../../state/CheckoutState';

export const CheckoutBreadcrumbs = React.memo(() => {
  const { t } = useTranslation('common');
  const checkoutState = useSnapshot(CheckoutState);
  const checkoutStep = checkoutState.checkoutStep;

  return (
    <Breadcrumb
      spacing='8px'
      separator={ <Box as={ CaretRight } color='gray.500' /> }
    >
      <BreadcrumbItem isCurrentPage={ checkoutStep === 'information' }>
        <BreadcrumbLink
          isCurrentPage={ checkoutStep === 'information' }
          href='#'
          tabIndex={ -1 }
          onClick={ () => {
            CheckoutState.checkoutStep = 'information';
          } }
          pointerEvents={ ['shipping', 'payment'].includes(checkoutStep)
            ? 'all'
            : 'none' }
        >
          { t('Information') }
        </BreadcrumbLink>
      </BreadcrumbItem>

      <BreadcrumbItem isCurrentPage={ checkoutStep === 'shipping' }>
        <BreadcrumbLink
          isCurrentPage={ checkoutStep === 'shipping' }
          href='#'
          tabIndex={ -1 }
          onClick={ () => {
            CheckoutState.checkoutStep = 'shipping';
          } }
          pointerEvents={ checkoutStep === 'payment' ? 'all' : 'none' }
        >
          { t('Shipping') }
        </BreadcrumbLink>
      </BreadcrumbItem>

      <BreadcrumbItem
        isCurrentPage={ checkoutStep === 'payment' }
      >
        <BreadcrumbLink
          isCurrentPage={ checkoutStep === 'payment' }
          href='#'
          tabIndex={ -1 }
          onClick={ () => {
            // CheckoutState.checkoutStep = 'payment';
          } }
          pointerEvents={ 'none' }
        >
          { t('Payment') }
        </BreadcrumbLink>
      </BreadcrumbItem>
    </Breadcrumb>
  );
});
CheckoutBreadcrumbs.displayName = 'CheckoutBreadcrumbs';
